@import '../../../App.scss';

// .bottom-wave{
//   position: relative;
//   top: 0%;
//   background-image: url(../../assets/Bgs/bg-wave-4.svg) ;
// 	min-height: 300px;
// 	background-repeat: no-repeat;
// 	-webkit-background-size: cover;
// 	-moz-background-size: cover;
// 	-o-background-size: cover;
// 	background-size: cover;
//   z-index: -1;
// 	@include respond(phone){
// 		background-size: contain;
// 		background-position-y: 150px;
// 	}
// }

.newsletter-wrapper {
	position: relative;
	background: rgb(60, 140, 231);
	background: linear-gradient(
		45deg,
		rgba(60, 140, 231, 1) 35%,
		rgba(0, 234, 255, 1) 100%
	);
	padding: 15px 0;
	&:before{
		position: absolute;
		content: url('../../../assets/Bgs/shape-square2.svg');
		top: -50%;
		left: 10%;
		z-index: -1000;
		@include respond(phone){
			top: -45%;
			left: -1%;
		}
	}

	.newsletter-container {
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 50px;
		@include respond(phone){
			gap: 0;
			flex-direction: column;
		}

		.newsletter-cta {
			text-align: center;

			.newsletter-cta-top {
				color: $white;
				font-size: 40px;
				font-weight: $bold-weight;
				@include respond(phone){
					font-size: 26px;
					line-height: 24px;
				}
			}

			.newsletter-cta-bottom {
				color: $white;
				font-size: 18px;
				font-weight: $semibold-weight;
				@include respond(phone){
					font-size: 14px;
					margin-bottom: 5px;
				}
			}
		}

		.newsletter-sub-box {
			flex-basis: 35%;
			@include respond(phone){
				width: 80%;
			}
			.newsletter-search-form {
				align-items: center;
				.newsletter-search-icon {
					display: flex;
					position: absolute;
					bottom: 10px;
					justify-items: center;
					padding-left: 12px;
					pointer-events: none;
				}
				.newsletter-search-field {
					display: block;
					height: 44px;
					padding-left: 40px;
					width: 100%;
					font-size: 14px;
					color: $grey-base;
					background-color: $grey-lighter;
					border-radius: 22px;
					outline: none;
					// border: 2px solid $blue-bg;
					transition: 0.25s;
					&:focus {
						background-color: $grey-light;
					}
				}
				.newsletter-btn-sub {
					height: 40px;
					width: 120px;
					font-size: 16px;
					position: absolute;
					right: 2px;
					bottom: 2px;
					color: $white;
					background-color: $green-base;
					border-radius: 20px;
					transition: 0.25s;
					&:hover {
						background-color: $grey-dark;
					}
					&:focus {
						outline: none;
					}
				}
			}
		}

		.newsletter-btns {
			position: absolute;
			right: 10%;
			display: flex;
			flex-direction: row;
			top: -100px;
			margin-left: 100px;
			align-items: baseline;
			@include respond(phone){
				top: -85px;
			}
			.btn-contact {
				padding: 26px;
				background-color: $yellow-base;
				position: relative;
				// top: -76px;
				transition: 0.25s;
				@include respond(phone){
					padding: 16px;
				}
				&:hover {
					transform: translateY(-2.5px);
					filter: brightness(90%);
				}
				img {
					width: 42px;
					height: auto;
				}
			}
			.btn-top {
				padding: 20px;
				background-color: $blue-base;
				position: relative;
				// top: -70px;
				transition: 0.25s;
				@include respond(phone){
					padding: 14px;
					//top: -74px;
				}
				&:hover {
					transform: translateY(-2.5px);
					filter: brightness(90%);
				}
				img {
					width: 32px;
					height: auto;
				}
			}
		}
	}
}
