@import './variables';
@import './typography';

// RESET
* {
	box-sizing: border-box;
	scroll-behavior: smooth;
	font-family: TTNorms, sans-serif;
	// color: $grey-brand;
	font-weight: $regular-weight;
	backface-visibility: hidden;
	font-variant-ligatures: no-common-ligatures;
	-webkit-font-variant-ligatures: no-common-ligatures;
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
}

body {
	margin: 0;
	overflow-y: overlay;
	min-height: 100vh;
	min-height: -webkit-fill-available;
}

html {
	height: -webkit-fill-available;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

select:focus {
	outline: none;
}

select::-webkit-scrollbar {
	width: 10px;
}
/* Track */
select::-webkit-scrollbar-track {
	background: $grey-light;
}

/* Handle */
select::-webkit-scrollbar-thumb {
	background: $yellow-base;
}

/* Handle on hover */
select::-webkit-scrollbar-thumb:hover {
	background: $yellow-base;
}

/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: $grey-light;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: $blue-brand;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: $yellow-base;
}

// input {
// 	font-family: Arial;
// }
