// GLOBAL SCSS
@import '../../App.scss';

// TOPNAV
.header-mobile {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 10px;
	background-color: $white;
	position: fixed !important;
	top: 0 !important;
	width: calc(100%) !important;
	height: 60px !important;
	z-index: 20000;
	box-shadow: $box-shadow-nav-top;

	.logo-container {
		height: 100%;
		width: 100%;
		display: flex;
		flex-basis: 65%;
		align-items: center;
		justify-content: flex-start;
		img {
			max-width: 150px;
			height: auto;
			cursor: pointer;
			filter: drop-shadow($drop-shadow-sm);
		}
	}
	.cart-container {
		height: 100%;
		width: 100%;
		flex-basis: 20%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.account-container {
		height: 100%;
		width: 100%;
		flex-basis: 15%;
		display: flex;
		align-items: center;
		justify-content: center;
		// button {
		// 	display: flex;
		// 	align-items: center;
		// 	justify-content: center;
		// 	height: 100%;
		// 	width: 100%;
		// 	img {
		// 		object-fit: cover;
		// 		border-radius: 50%;
		// 		border: 2px solid $grey-light;
		// 		box-shadow: $box-shadow-circle;
		// 	}
		// }
		.account-btn {
			width: 46px;
			height: 46px;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: 0.2s;
			img {
				width: 46px;
				height: 46px;
				border-radius: 50%;
				object-fit: cover;
				border: 2px solid $yellow-base;
				box-shadow: $box-shadow-sm;
				transition: all 0.2s;
			}
			span {
				padding: 3px;
				font-size: 36px;
				color: $white;
				text-shadow: $txt-shadow;
				background-color: $blue-light;
				border-radius: 50%;
				transition: 0.2s;
				&:hover {
					background-color: $blue-medium;
				}
			}
			&:hover {
				transform: translateY(-2px);
			}
		}
		.account-logged-out {
			@extend .account-btn;
			span {
				padding: 2px;
				font-size: 42px;
				color: $blue-light;
				text-shadow: $txt-shadow;
				background-color: $grey-lighter;
				border-radius: 50%;
				transition: 0.2s;
				&:hover {
					background-color: $grey-light;
				}
			}
		}
		.account-dropdown {
			// overflow: hidden;
			width: 70vw;
			position: absolute;
			top: 70px;
			right: 10px;
			background-color: $white;
			// border-radius: 5px;
			box-shadow: $box-shadow;
			&:before {
				position: absolute;
				top: -14px;
				left: 230px;
				border-top: 15px solid $white;
				border-left: 15px solid rgba(0, 0, 0, 0);
				border-right: 15px solid rgba(0, 0, 0, 0);
				content: '';
				display: inline-block;
				height: 0;
				vertical-align: top;
				width: 0;
				transform: rotate(180deg);
				filter: drop-shadow(1px 4px 2px rgba(12, 36, 97, 0.1));
			}
			.account-menu {
				text-align: left;
				display: flex;
				flex-direction: column;
				font-size: 18px;
				inline-size: 70vw;
				overflow-wrap: break-word;

				.conta {
					text-transform: capitalize;
					padding: 15px;
					padding-bottom: 0;
					font-weight: $semibold-weight;
					font-size: 22px;
				}
				.email {
					padding: 15px;
					padding-top: 0;
					font-weight: $semibold-weight;
					color: $blue-brand;
				}
				.account-menu-item {
					padding: 15px;
					cursor: pointer;
					display: flex;
					align-items: center;
					&:hover {
						background-color: $blue-brand;
						color: $white;
						span {
							color: $white;
						}
					}
					span {
						margin-right: 5px;
						font-size: 32px;
						text-shadow: $txt-shadow;
					}
					#minha-conta-icon {
						color: $blue-medium;
					}
					#compra-rapida-icon {
						color: $green-medium;
					}
					#atendimento-icon {
						color: $yellow-base;
					}
					#sair-icon {
						color: $grey-medium;
					}
				}
				.sair {
					font-weight: $semibold-weight;
					&:hover {
						background-color: $grey-dark;
					}
				}

				.account-menu-hr {
					margin: 0;
					border: 1px solid $grey-light;
				}
			}
		}
	}

	// .account-cart-container {
	// 	display: flex;
	// 	flex-direction: row;
	// 	justify-content: space-between;
	// 	align-items: center;
	// 	gap: 20px;
	// 	.account-btn {
	// 		width: 32px;
	// 		height: 32px;
	// 		border-radius: 50%;
	// 		display: flex;
	// 		justify-content: center;
	// 		align-items: center;
	// 		transition: all 0.2s;

	// 		span {
	// 			font-size: 36px;
	// 			color: $grey-dark;
	// 			transition: all 0.2s;
	// 		}
	// 	}
	// 	.menu-container {
	// 		margin-left: 10px;
	// 		button {
	// 			width: 32px;
	// 			height: 32px;
	// 			border-radius: 50%;
	// 			display: flex;
	// 			justify-content: center;
	// 			align-items: center;
	// 			transition: all 0.2s;

	// 			span {
	// 				font-size: 46px;
	// 				color: $grey-dark;
	// 				transition: all 0.2s;
	// 				text-shadow: $txt-shadow;
	// 			}
	// 		}
	// 	}
	// }
}

// BOTTOMNAV
.bottomnav-wrapper {
	background-color: $white;
	position: fixed !important;
	bottom: 0 !important;
	width: calc(100%) !important;
	height: 60px !important;
	z-index: 20000;
	box-shadow: $box-shadow-nav-bot;
	// &:before {
	// 	content: ' ';
	// 	display: flex;
	// 	width: 100%;
	// 	height: 2px;
	// 	background-color: $grey-light;
	// }
	.bottomnav-container {
		position: relative;
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 10px;
		.bottomnav-btn {
			display: flex;
			align-items: center;
			justify-content: center;
			transition: all 0.2s;
		}
		.home,
		.categoria,
		.compra-rapida,
		.atendimento {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			width: 100%;
			transition: 0.2s;
			span {
				color: $grey-medium;
				font-size: 36px;
			}
			&:hover {
				border-top: 4px solid $yellow-base;
				span {
					color: $grey-dark;
					text-shadow: $txt-shadow;
				}
			}
		}
		.pesquisa {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			width: 100%;
			transition: 0.2s;
			span {
				font-size: 42px;
				border-radius: 50%;
				padding: 5px;
				color: $white;
				box-shadow: $box-shadow-circle;
				background-size: 200% auto;
				background-image: linear-gradient(
					to right,
					$green-medium 0%,
					#00bbff 50%,
					$green-light 100%
				);
				transition: 0.5s;
			}
			&:hover {
				transform: translateY(-0.5px);
				span {
					background-position: right center;
				}
			}
		}
	}
}

// NAVMENU

.navmenu-mobile {
	display: none;
	position: fixed !important;
	top: 60px !important;
	width: 100%;
	background-color: $blue-base;
	z-index: 19999;
	padding: 20px 10px;
	width: 100%;
	box-shadow: $box-shadow;
	animation: myAnim 200ms ease-in-out 0s 1 normal forwards;
	@keyframes myAnim {
		0% {
			opacity: 0;
			transform: translateY(-100px);
		}

		100% {
			opacity: 1;
			transform: translateX(0);
		}
	}
}

.expand-navmenu-mobile {
	@extend .navmenu-mobile;
	display: flex;
}

.search-mobile-wrapper {
	display: block;
	width: 100%;
	transition: 0.2s;
	.search-form {
		align-items: center;
		.search-icon {
			display: flex;
			position: absolute;
			bottom: 10px;
			justify-items: center;
			padding-left: 12px;
			pointer-events: none;
		}
		.search-field {
			box-shadow: $box-shadow;
			display: block;
			height: 45px;
			padding-left: 40px;
			width: 100%;
			font-size: 14px;
			color: $grey-base;
			outline: none;
			background-color: $grey-lighter;
			border-radius: 22px;
			transition: 0.25s;
			&:focus {
				background-color: $grey-light;
				border: 1px solid $blue-medium;
				//outline: 1px solid $blue-medium;
			}
		}
		.btn-pesquisar {
			height: 40px;
			width: 120px;
			font-size: 16px;
			font-weight: $semibold-weight;
			position: absolute;
			right: 3px;
			bottom: 2px;
			color: $white;
			background-color: $yellow-base;
			border-radius: 20px;
			transition: 0.25s;
			&:hover {
				background-color: $grey-dark;
			}
			&:focus {
				outline: none;
			}
		}
		.btn-pesquisar2 {
			height: 100%;
			width: 30px;
			position: absolute;
			right: 3px;
			bottom: 1px;
			&:focus {
				outline: none;
			}
		}
	}
}
